import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { getTokenOrRefresh } from './token_util';
import './custom.css'
import {PropertyId, ResultReason, User } from 'microsoft-cognitiveservices-speech-sdk';
import { useEffect, useRef } from 'react';
import { Player, FS_SDK_EVENTS_NAME, FS_QUALITY_VALUES } from 'furioos-sdk';

const speechsdk = require('microsoft-cognitiveservices-speech-sdk')


export default class App extends Component {
    // //bind the speech to text functions to the correct "this" value in the constructor of the component
    // constructor(props) {
    //     super(props);
    //     this.onRecognizing = this.onRecognizing.bind(this);
    //     this.onSessionStarted = this.onSessionStarted.bind(this);
    //     this.onSessionStopped = this.onSessionStopped.bind(this);
    //     this.onCanceled = this.onCanceled.bind(this);
    //   }
    // Create a recognizer variable for Speech To Text
    recognizer = null;

    audioContext = null;

    //hard-coded bool to check if convoai is already set
    isSessionConfigSent = false;

    //flag to check if speech recognition is already running
    isSpeechRecRunning = false;


    async componentDidMount() {

        console.log("Version number 1.2.6.25052023")

        window.addEventListener("beforeunload", this.handleBeforeUnload);

        this.checkForMicrophonePermission();

        // check for valid speech key/region
        const tokenRes = await getTokenOrRefresh();
        if (tokenRes.authToken === null) {
            this.setState({
                displayText: 'FATAL_ERROR: ' + tokenRes.error
            });
        }
        const options = {
            whiteLabel: true,
            hideToolbar: false,
            hideTitle: true,
            hidePlayButton: false,
            debugAppMode: false,
            inactiveTimeout: 60000,
          };
        
        this.player = new Player(process.env.REACT_APP_SDKLINKID ,"furioos_container", options);
    
        // Bind player loaded
        this.player.on(FS_SDK_EVENTS_NAME.LOAD, () => {
        console.log("SDK client FIRED: Player loaded");
        });
    
        // Bind application install progress
        this.player.on(FS_SDK_EVENTS_NAME.ON_APP_INSTALL_PROGRESS, (data) => {
        console.log("SDK client FIRED: App install progress", data);
        });
    
        // Bind application start
        this.player.on(FS_SDK_EVENTS_NAME.ON_APP_START, () => {
        console.log("SDK client FIRED: App start");
        });
    
        // Bind stream start
        this.player.on(FS_SDK_EVENTS_NAME.ON_STREAM_START, () => {
        console.log("SDK client FIRED: Stream start");
        });
    
        // Bind stream start
        this.player.on(FS_SDK_EVENTS_NAME.ON_SDK_START, () => {
        console.log("SDK client FIRED: SDK start");
        //NOTE: Just for lien
        //this.sendSessionConfig();
        });
    
        // Bind SDK messages
        this.player.on(FS_SDK_EVENTS_NAME.ON_SDK_MESSAGE, (data) => {
        console.log("SDK Message Received:", data);

        const action = data.action;
        if (action === "mute") {
        console.log("Received mute action");
        this.stopSpeechRecognition();
        }
        if (action === "unmute") {
        console.log("Received unmute action");
        this.startSpeechRecognition();
        }
        
        if (data.includes("unmute"))
        {
            console.log("unmuting!")
            this.startSpeechRecognition()
        }
        else if (data.includes("mute"))
        {
            console.log("muting!")
            this.stopSpeechRecognition();
        }
        });
    
        // Bind an event that lets you know if you can resume session
        this.player.on(FS_SDK_EVENTS_NAME.ON_RESUME_SESSION, ({ canResumeSession }) => {
        if(canResumeSession) {
            this.player.resumeSession();
        }
        });
    
        // Bind session stoppeds
        this.player.on(FS_SDK_EVENTS_NAME.ON_SESSION_STOPPED, () => {
        console.log("SDK client FIRED: Session Stopped");
        this.stopSpeechRecognition()
        });


    }


    componentWillUnmount() {

        window.removeEventListener("beforeunload", this.handleBeforeUnload);

        // Remove player loaded event listener
        this.player.off(FS_SDK_EVENTS_NAME.LOAD);
      
        // Remove application install progress event listener
        this.player.off(FS_SDK_EVENTS_NAME.ON_APP_INSTALL_PROGRESS);
      
        // Remove application start event listener
        this.player.off(FS_SDK_EVENTS_NAME.ON_APP_START);
      
        // Remove stream start event listener
        this.player.off(FS_SDK_EVENTS_NAME.ON_STREAM_START);
      
        // Remove SDK start event listener
        this.player.off(FS_SDK_EVENTS_NAME.ON_SDK_START);
      
        // Remove SDK message event listener
        this.player.off(FS_SDK_EVENTS_NAME.ON_SDK_MESSAGE);
      
        // Remove resume session event listener
        this.player.off(FS_SDK_EVENTS_NAME.ON_RESUME_SESSION);
      
        // Remove session stopped event listener
        this.player.off(FS_SDK_EVENTS_NAME.ON_SESSION_STOPPED);
    }

    handleBeforeUnload = (event) => {
        console.log("refreshing or exiting webpage. Stopping Player")
        this.player.stop()
      }
      
    //TODO: Check if constantly calling this will cause any issues
    startAudioContext() {
        console.log("start audio context")
        // Create an AudioContext
        this.audioContext = new AudioContext();
        // Start the audio context
        this.audioContext.resume();
      }

    checkForMicrophonePermission()
    {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ audio: true })
            //   .then(stream => {
            //     // Do something with the stream
            //   })
              .catch(error => {
                console.log("Error getting microphone access: " + error);
              });
          } else {
            console.log("getUserMedia not supported on this browser");
          }
    }

    async sendSessionConfig(){
        console.log("session config not set. setting now.")
        const params = new URLSearchParams(window.location.search);
        let UserId = params.get("UserId");
        let CharacterId = params.get("CharacterId");
        let ConvoId = params.get("ConvoId");
        let MoodId = params.get("MoodId");
        console.log("SessionConfig: UserId: " + UserId + ", CharacterId: " + CharacterId + ", ConvoId: " + ConvoId + ", MoodId: " + MoodId);
        const message = UserId + '/' + CharacterId + '/' + ConvoId;
        console.log("message to send: " + message);
        await this.sendFurioosMessage("SessionConfig", message)
        this.isSessionConfigSent = true;

        //this.startSpeechRecognition();

    }
    
    async sendFurioosMessage(status,message){
        console.log("SDK Example: Call sendSDKMessage", new Date());
        this.player.sendSDKMessage({
          "status": status,
          "message": message
        });
    }
    
    
    async startSpeechRecognition() {
        if (this.isSpeechRecRunning === true)
        {
            console.log("speech recognition is already running. aborting startSpeechRec.")
            return;
        }
        const tokenObj = await getTokenOrRefresh();
        const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);

        // https://learn.microsoft.com/en-gb/azure/cognitive-services/speech-service/how-to-recognize-speech?pivots=programming-language-csharp#change-how-silence-is-handled
        // speechConfig.setProperty(PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs, "1000")
        // speechConfig.setProperty(PropertyId.Speech_SegmentationSilenceTimeoutMs, "750")

        speechConfig.speechRecognitionLanguage = 'en-US';
        const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
        if (this.recognizer === null)
        {
            this.recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);
        }
        else
        {
            console.log("There is already a recognizer within the session. Aborting startSpeechRec");
            return;
        }


        // Start the continuous recognition. Note that, in this continuous scenario, activity is purely event-
        // driven, as use of continuation (as is in the single-shot sample) isn't applicable when there's not a
        // single result.

        
        // this.recognizer.recognizing = this.onRecognizing;
        // //recognizer.recognized = this.onRecognized;
        // this.recognizer.canceled = this.onCanceled;
        // this.recognizer.sessionStarted = this.onSessionStarted;
        // this.recognizer.sessionStopped = this.onSessionStopped;

        this.recognizer.startContinuousRecognitionAsync();

        this.recognizer.recognizing = (sender, recognitionEventArgs) => {
            if (recognitionEventArgs && recognitionEventArgs.result && recognitionEventArgs.result.text) {
                var result = recognitionEventArgs.result;
                this.sendFurioosMessage("Recognising",result.text)
            }
        }
        this.recognizer.canceled = (sender, cancellationEventArgs) => {
            console.log("cancelled!")
            console.log(cancellationEventArgs);
            console.log("Connection failure. Attempting to reconnect...");  
            // Stop the current recognition before attempting to reconnect  
      
            this.stopSpeechRecognition();  
            // Call the startSpeechRecognition function to restart the process  
            this.startSpeechRecognition();  
        }
          
        this.recognizer.sessionStarted = (sender, sessionEventArgs) => {
        this.isSpeechRecRunning = true;
        console.log("Session started"+sessionEventArgs.sessionId)
        }
          
        this.recognizer.sessionStopped = (sender, sessionEventArgs) => {
        console.log("Session stopped"+ sessionEventArgs.sessionId)
        }

        this.recognizer.recognized = (sender, recognitionEventArgs) => {
            if (recognitionEventArgs && recognitionEventArgs.result && recognitionEventArgs.result.text) {
                var result = recognitionEventArgs.result;
                console.log("recognised"+result.text)
                this.sendFurioosMessage("Recognised",result.text)

                this.stopSpeechRecognition()
            }
        }
    }

    // onRecognizing(sender, recognitionEventArgs) {
    //     var result = recognitionEventArgs.result;
    //     console.log("recognising"+result.text)
    // }
    // // onRecognized(sender, recognitionEventArgs) {
    // //     var result = recognitionEventArgs.result;
    // //     console.log("recognised"+result.text)
    // //     this.sendFurioosSTTMessage("Recognised",result.text)
    // // }
    // onSessionStarted(sender, sessionEventArgs) {
    //     console.log("Session started"+sessionEventArgs.sessionId)
    // }
    // onSessionStopped(sender, sessionEventArgs) {
    //     console.log("Session stopped"+ sessionEventArgs.sessionId)
    // }
    // onCanceled (sender, cancellationEventArgs) {
    //     console.log("cancelled!")
    //     console.log(cancellationEventArgs);
    // }
    
    stopSpeechRecognition() {
        if (this.recognizer != null)
        {
            this.recognizer.stopContinuousRecognitionAsync(
                () => { 
                    if (this.isSpeechRecRunning === true)
                    {
                        console.log("Speech recognition is running, stopping now");
                    }
                    else
                    {
                        console.log("speech recognition is already stopped.")
                    }
                    console.log('Speech recognition stopped');
                    this.isSpeechRecRunning = false;
                    this.recognizer = null;
                },
                (error) => { console.log(error); }
                );
        }
        else
        {
            console.log("There is currently no speech recognizer! Aborting stop speech rec.")
        }

    }


    

    render() {
          // Add the event listener here
        document.addEventListener('click', () => {
            this.startAudioContext();
        });
        return (
            <Container className="app-container">
                <h1 className="display-4 mb-3"></h1>

                <div className="row main-container">
                    <div>
                    <div id="furioos_container"  style={{ width: '1280px', height: '720px' }}/></div>
                    {/* <div className="mt-2">
                    <i className="fas fa-microphone fa-lg mr-2" onClick={() => this.sendFurioosMessage("testing","testing")}></i>
                    send sample message to furioos
                    </div>
                    <div className="mt-2">
                    <i className="fas fa-microphone fa-lg mr-2" onClick={() => this.sendSessionConfig()}></i>
                    debugging button for starting session
                    </div>
                    <div className="mt-2">
                    <i className="fas fa-microphone fa-lg mr-2" onClick={() => this.startSpeechRecognition()}></i>
                    startspeech
                    </div>
                    <div className="mt-2">
                    <i className="fas fa-microphone fa-lg mr-2" onClick={() => this.stopSpeechRecognition()}></i>
                    stopspeech
                    </div> */}
                </div>
            </Container>
        );
    }

    // render() {
    //     return (
    //         <Container className="app-container">
    //             <h1 className="display-4 mb-3">Speech sample app</h1>

    //             <div className="row main-container">
    //                 <div className="col-6">
    //                     <i className="fas fa-microphone fa-lg mr-2" onClick={() => this.sttFromMic()}></i>
    //                     Convert speech to text from your mic.

    //                     <div className="mt-2">
    //                     <i className="fas fa-microphone fa-lg mr-2" onClick={() => this.realtimeSttFromMic()}></i>
    //                     Start realtime speech to text from your mic.
    //                     </div>

    //                     <div className="mt-2">
    //                         <label htmlFor="audio-file"><i className="fas fa-file-audio fa-lg mr-2"></i></label>
    //                         <input 
    //                             type="file" 
    //                             id="audio-file" 
    //                             onChange={(e) => this.fileChange(e)} 
    //                             style={{display: "none"}} 
    //                         />
    //                         Convert speech to text from an audio file.
    //                     </div>
    //                 </div>
    //                 <div className="col-6 output-display rounded">
    //                     <code>{this.state.displayText}</code>
    //                 </div>
    //                 <div>
    //                 <div id="furioos_container"  style={{ width: '800px', height: '600px' }}/></div>

    //                 <div className="mt-2">
    //                 <i className="fas fa-microphone fa-lg mr-2" onClick={() => this.sendFurioosSTTMessage("testing","testing")}></i>
    //                 send sample message to furioos
    //                 </div>

    //             </div>
    //         </Container>
    //     );
    // }
}